export const backendLogCodes = {
  RECEIVE_USER_SINGLE_ACCESS: "RECEIVE_USER_SINGLE_ACCESS",
  LOST_USER_SINGLE_ACCESS: "LOST_USER_SINGLE_ACCESS",
  LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
  USER_LOGOUT_SUCCESS: "USER_LOGOUT_SUCCESS",
  ADVANCED_AUTO_LOGOUT: "ADVANCED_AUTO_LOGOUT",
  SINGLE_ACCESS_LOGOUT: "SINGLE_ACCESS_LOGOUT",
  PONTOMAIS_AUTO_LOGOUT: "PONTOMAIS_AUTO_LOGOUT",
  ADD_USERS_TO_GROUP: "ADD_USERS_TO_GROUP",
  REMOVE_USERS_FROM_GROUP: "REMOVE_USERS_FROM_GROUP",
  ADD_GROUPS_TO_USER: "ADD_GROUPS_TO_USER",
  REMOVE_GROUPS_FROM_USER: "REMOVE_GROUPS_FROM_USER",
  USER_REQUEST_SINGLE_ACCESS: "USER_REQUEST_SINGLE_ACCESS",
  USER_REQUEST_PASSWORD_CHANGE: "USER_REQUEST_PASSWORD_CHANGE",
  CHANGE_USER_PASSWORD: "CHANGE_USER_PASSWORD",
  CHANGE_OWN_PASSWORD: "CHANGE_OWN_PASSWORD",
  ADD_VACATION_TO_USER: "ADD_VACATION_TO_USER",
  REMOVE_VACATION_FROM_USER: "REMOVE_VACATION_FROM_USER",
  ACTIVATE_SSO: "ACTIVATE_SSO",
  DISABLE_SSO: "DISABLE_SSO",
  UPDATE_USER: "UPDATE_USER",
  DELETE_USER: "DELETE_USER",
  ADD_NEW_USER: "ADD_NEW_USER",
  ADDED_GROUP_RULES: "ADDED_GROUP_RULES",
  REMOVED_GROUP_RULES: "REMOVED_GROUP_RULES",
  DELETED_GROUPS: "DELETED_GROUPS",
  UPDATED_GROUP_RULES: "UPDATED_GROUP_RULES",
  SENIOR_AUTO_LOGOUT: "SENIOR_AUTO_LOGOUT",
  CANCEL_ACTIVATION_SSO_ASYNC: "CANCEL_ACTIVATION_SSO_ASYNC",
  ACTIVATE_SSO_ASYNC: "ACTIVATE_SSO_ASYNC",
  AUTO_LOGOUT: "AUTO_LOGOUT",
  AUTO_LOGOUT_AFTER_CLOSING_WINDOW: "AUTO_LOGOUT_AFTER_CLOSING_WINDOW",
  ADD_ADMIN_PERMISSION: "ADD_ADMIN_PERMISSION",
  REMOVE_ADMIN_PERMISSION: "REMOVE_ADMIN_PERMISSION",
  CUSTOMIZE_LOGIN: "CUSTOMIZE_LOGIN",
};

export const loginAttemptCodes = [
  "USER_BLOCK_HOUR",
  "USER_SUCCESS",
  "USER_WRONG_PASSWORD",
  "USER_ON_VACATION",
  "USER_EXPIRED_TRIAL",
  "USER_ON_VACATION_IN_PONTOMAIS",
  "USER_DISABLED_DOMAIN",
  "USER_BLOCK_DAY",
  "USER_SPECIAL_BLOCK_DAY",
  "USER_BLOCK_IP",
  "USER_WITHOUT_GROUP",
  "USER_WITHOUT_ACTIVE_GROUP",
  "USER_NOT_FOUND",
  "USER_BLOCK_NAVIGATOR",
  "USER_BLOCK_OPERATIONAL_SYSTEM",
  "USER_UNKNOWN_PONTO_MAIS",
  "USER_BLOCK_PONTO_MAIS",
  "GROUPS_WITHOUT_RULES",
  "INVALID_PONTO_MAIS_TOKEN",
  "INVALID_SENIOR_CREDENTIALS",
  "USER_UNKNOWN_SENIOR",
  "USER_BLOCK_SENIOR",
];

export const customLogCodes = {
  reports_access_time: "reports_access_time",
};

export const expandableLogCodes = [customLogCodes.reports_access_time];

export const maxLogoDimension = 500;

export const minLogoDimension = 40;

export const testCompanies = [
  "conectanuvem.com.br",
  "dev.conectanuvem.com.br",
  "baianor.online",
  "conectacontact.com",
  "conectanuvem.onmicrosoft.com",
  "desafioconectanuvem.com.br",
  "dev-thiago.shop",
  "gourmetizeti.xyz",
  "jambutech.online",
  "lgsweb.com.br",
  "LGSWeb.onmicrosoft.com",
  "mcoelhotech.com.br",
  "nerobr.com",
  "samrobot.com.br",
  "solucoestecnologicas.net",
  "tainhadelivery.com.br",
  "thiago.fun",
  "conectaplanning.com.br",
  "b.samrobot.com.br",
];

export const VR_GENTE_CLOCK_TIME_PROVIDER = "ponto_mais";
export const SENIOR_CLOCK_TIME_PROVIDER = "senior";
export const TANGERINO_CLOCK_TIME_PROVIDER = "tangerino";
export const DEFAULT_CLOCK_TIME_PROVIDER = VR_GENTE_CLOCK_TIME_PROVIDER;
export const SCOPE_ERRO_CODE = "INVALID_GOOGLE_ACCESS_TOKEN";

export const WORKSPACE_APP_URL_BY_ENV = {
  development:
    "https://workspace.google.com/marketplace/app/dev_conecta_control/708943323522",
  production:
    "https://workspace.google.com/marketplace/app/conecta_control/404856124805",
};

export const emails = {
  financial: "financeiro@conectasuite.com",
};

export const SUITE_DEV_PROJECT_NAME = "dev-conectasuite";
export const SUITE_PROD_PROJECT_NAME = "conectasuite";
