<template>
  <v-snackbar
    v-if="!loading"
    color="primary"
    v-model="scheduleMeetingSnackbar"
    right
    timeout="-1"
    max-width="600"
    style="margin-bottom: 80px"
    transition="slide-x-reverse-transition"
  >
    <v-row class="justify-center align-center">
      <v-col class="d-flex align-center" cols="8">
        <v-badge color="green" dot bottom offset-x="10" offset-y="10">
          <v-avatar size="50">
            <v-img
              aspect-ratio="1/1"
              :src="commercial_responsable_img"
              alt="user photo"
            />
          </v-avatar>
        </v-badge>
        <strong class="ml-4">Laís Lopes</strong>
      </v-col>
      <v-spacer></v-spacer>

      <v-col cols="2" sm="1">
        <v-btn icon @click="scheduleMeeting(false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-row class="flex-column">
      <span class="px-4 pt-2 pb-4">
        {{
          `${$ml.get("hi")} ${currentUser.name}, ${$ml.get(
            "schedule_meeting_description"
          )}`
        }}
      </span>

      <v-col class="d-flex align-center justify-end">
        <v-btn
          @click="scheduleMeeting"
          class="ml-4 text-none text-body-2 font-weight-medium px-5"
          color="accent"
        >
          {{ $ml.get("schedule_meeting") }}
        </v-btn>

        <v-btn
          class="text-none text-body-2 font-weight-medium ml-2"
          text
          @click="scheduleMeeting(false)"
        >
          {{ $ml.get("maybe_later") }}
        </v-btn>
      </v-col>
    </v-row>
  </v-snackbar>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { getAnalytics, logEvent } from "firebase/analytics";
import { calendarSchedule, commercial_responsable_img } from "@/helpers/links";
import { sendScheduleEmail } from "@/helpers/utils";
import moment from "moment";
export default {
  data: () => ({
    scheduleMeetingSnackbar: false,
    calendarSchedule,
    commercial_responsable_img,
  }),

  computed: {
    ...mapGetters([
      "currentUser",
      "company",
      "controlInTrialOrInexistent",
      "loading",
    ]),
  },

  methods: {
    ...mapMutations(["setMeetingSnackbarVisibility"]),

    logScheduleMeetingInAnalytics() {
      const analytics = getAnalytics();
      logEvent(analytics, "schedule_meeting", {
        main_domain: this.company.main_domain,
        clicked_where: "clicked from snackbar",
        date: moment().format("DD/MM/YYYY"),
      });
    },

    scheduleMeeting(schedule = true) {
      this.scheduleMeetingSnackbar = false;
      this.setMeetingSnackbarVisibility(false);

      if (schedule) {
        sendScheduleEmail();
        window.open(this.calendarSchedule, "_blank");
        localStorage.setItem("scheduleMeeting", "true");
        this.logScheduleMeetingInAnalytics();
        return;
      }

      localStorage.setItem("scheduleMeeting", "false");
      this.updateLocalScheduleMeetingStorageDates();
    },

    updateLocalScheduleMeetingStorageDates() {
      const lastVisitDate = localStorage.getItem("lastVisitDate");
      const currentDate = new Date().toLocaleDateString();
      if (lastVisitDate !== currentDate) {
        localStorage.setItem("lastVisitDate", currentDate);
      }
    },

    initScheduling() {
      if (!this.controlInTrialOrInexistent) return;

      const storageMeetingController = localStorage.getItem("scheduleMeeting");

      if (!storageMeetingController) {
        this.scheduleMeetingSnackbar = true;
        this.setMeetingSnackbarVisibility(true);
        return;
      }

      const lastVisitDate = localStorage.getItem("lastVisitDate");
      const currentDate = new Date().toLocaleDateString();

      if (lastVisitDate !== currentDate) {
        localStorage.setItem("lastVisitDate", currentDate);
        this.scheduleMeetingSnackbar = true;
        this.setMeetingSnackbarVisibility(true);
      }
    },
  },

  beforeMount() {
    this.initScheduling();
  },
};
</script>
