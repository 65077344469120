import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
import "@/plugins/ml";

Vue.use(VueRouter);

const routes = [
  {
    path: "*",
    name: "Error",
    component: () => import("@/views/Error.vue"),
  },
  {
    path: "/",
    name: "Home",
    component: () => import("@/views/Home.vue"),
    meta: {
      requiresAuth: true,
      title: "home",
    },
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/Login.vue"),
  },
  {
    path: "/login/beta",
    name: "BetaLogin",
    component: () => import("@/views/Login.vue"),
    props: { ms: true },
  },
  {
    path: "/accounts/reset-password",
    name: "ResetPassword",
    component: () => import("@/views/ResetPassword.vue"),
  },
  {
    path: "/accounts/request-single-access",
    name: "RequestSingleAccess",
    component: () => import("@/views/RequestSingleAccess.vue"),
  },
  {
    path: "/accounts/request-password-change",
    name: "RequestPasswordChange",
    component: () => import("@/views/RequestPasswordChange.vue"),
  },
  {
    path: "/auth/callback",
    name: "Callback",
    component: () => import("@/views/Callback.vue"),
  },
  {
    path: "/auth/login-as",
    name: "LoginAs",
    component: () => import("@/views/Callback.vue"),
  },
  {
    path: "/auth/callback/ms",
    name: "MsCallback",
    component: () => import("@/views/auth/CallbackMs.vue"),
  },
  {
    path: "/activation-steps",
    name: "ActivationSteps",
    component: () => import("@/views/ActivationSteps.vue"),
    meta: {
      requiresAuth: true,
      title: "active_control",
    },
  },
  {
    path: "/activation-completed",
    name: "SuccessfulActivation",
    component: () => import("@/views/SuccessfulActivation.vue"),
    meta: {
      requiresAuth: true,
      title: "active_control",
    },
  },
  {
    path: "/users",
    name: "Users",
    component: () => import("@/views/users/All.vue"),
    meta: {
      requiresAuth: true,
      subpages: ["/users"],
      title: "users",
      breadcrumb: [
        { key: "home", path: "Home", show: true },
        { key: "users", path: "Users", show: true },
      ],
    },
  },
  {
    path: "/users/:id",
    name: "User",
    component: () => import("@/views/users/User.vue"),
    meta: {
      requiresAuth: true,
      title: "user",
      breadcrumb: [
        { key: "home", path: "Home", show: true },
        { key: "users", path: "Users", show: true },
        { key: "get_user_name", show: true },
        {
          key: "get_user_current_edit",
          show: store.getters.editUserShifts,
        },
      ],
    },
  },
  {
    path: "/admin",
    name: "AdminFunctions",
    component: () => import("@/views/admin/AdminFunctions.vue"),
    meta: {
      requiresAuth: true,
      requiresControlAdmin: true,
      title: "user_permissions",
      breadcrumb: [
        { key: "home", path: "Home", show: true },
        { key: "user_permissions", path: "AdminFunctions", show: true },
      ],
    },
  },
  {
    path: "/groups",
    name: "Groups",
    component: () => import("@/views/groups/All.vue"),
    meta: {
      requiresAuth: true,
      title: "groups",
      breadcrumb: [
        { key: "home", path: "Home", show: true },
        { key: "groups", path: "Groups", show: true },
      ],
    },
  },
  {
    path: "/groups/:key",
    name: "Group",
    component: () => import("@/views/groups/Group.vue"),
    meta: {
      requiresAuth: true,
      title: "group",
      breadcrumb: [
        { key: "home", path: "Home", show: true },
        { key: "groups", path: "Groups", show: true },
        { key: "get_group_name", show: true },
        {
          key: "get_group_current_edit",
          show: store.getters.editGroupMode,
        },
      ],
    },
  },
  {
    path: "/password-settings",
    name: "PasswordSettings",
    component: () => import("@/views/PasswordSettings.vue"),
    meta: {
      requiresAuth: true,
      title: "password_settings",
      breadcrumb: [
        { key: "home", path: "Home", show: true },
        { key: "password_settings", path: "PasswordSettings", show: true },
      ],
    },
  },
  {
    path: "/integrations",
    name: "Integrations",
    component: () => import("@/views/Integrations.vue"),
    meta: {
      requiresAuth: true,
      title: "integrations",
      breadcrumb: [
        { key: "home", path: "Home", show: true },
        { key: "integrations", path: "Integrations", show: true },
      ],
    },
  },
  {
    path: "/reports",
    name: "Report",
    component: () => import("@/views/logs/AllReports.vue"),
    meta: {
      requiresAuth: true,
      title: "reports",
      breadcrumb: [
        { key: "home", path: "Home", show: true },
        { key: "reports", path: "Report", show: true },
      ],
    },
  },
  {
    path: "/reports/loginlogout",
    name: "ReportLoginLogout",
    component: () => import("@/views/logs/ReportsLoginLogout.vue"),
    meta: {
      requiresAuth: true,
      title: "reports_loginlogout_title",
      breadcrumb: [
        { key: "home", path: "Home", show: true },
        { key: "reports", path: "Report", show: true },
        { key: "reports_loginlogout", path: "ReportLoginLogout", show: true },
      ],
    },
  },
  {
    path: "/reports/users",
    name: "ReportUsers",
    component: () => import("@/views/logs/ReportsUsers.vue"),
    meta: {
      requiresAuth: true,
      title: "reports_users_title",
      breadcrumb: [
        { key: "home", path: "Home", show: true },
        { key: "reports", path: "Report", show: true },
        { key: "reports_users", path: "ReportUsers", show: true },
      ],
    },
  },
  {
    path: "/reports/groups",
    name: "ReportGroups",
    component: () => import("@/views/logs/ReportsGroups.vue"),
    meta: {
      requiresAuth: true,
      title: "reports_groups_title",
      breadcrumb: [
        { key: "home", path: "Home", show: true },
        { key: "reports", path: "Report", show: true },
        { key: "reports_groups", path: "ReportGroups", show: true },
      ],
    },
  },
  {
    path: "/reports/access_duration",
    name: "ReportAccessTime",
    component: () => import("@/views/logs/ReportsAccessTime.vue"),
    meta: {
      requiresAuth: true,
      title: "reports_access_time_title",
      breadcrumb: [
        { key: "home", path: "Home", show: true },
        { key: "reports", path: "Report", show: true },
        {
          key: "reports_access_time",
          path: "ReportAccessTime",
          show: true,
        },
      ],
    },
  },
  {
    path: "/reports/user_permissions",
    name: "ReportUserPermissions",
    component: () => import("@/views/logs/ReportsUserPermissions.vue"),
    meta: {
      requiresAuth: true,
      title: "user_permissions",
      breadcrumb: [
        { key: "home", path: "Home", show: true },
        { key: "reports", path: "Report", show: true },
        {
          key: "user_permissions",
          path: "ReportUserPermissions",
          show: true,
        },
      ],
    },
  },
  {
    path: "/reports/customize_login",
    name: "ReportsCustomizeLogin",
    component: () => import("@/views/logs/ReportsCustomizeLogin.vue"),
    meta: {
      requiresAuth: true,
      title: "user_permissions",
      breadcrumb: [
        { key: "home", path: "Home", show: true },
        { key: "reports", path: "Report", show: true },
        {
          key: "customize_login_label",
          path: "ReportsCustomizeLogin",
          show: true,
        },
      ],
    },
  },
  {
    path: "/customize",
    name: "CustomizeLogin",
    component: () => import("@/views/CustomizeLogin.vue"),
    meta: {
      title: "customize_login",
      requiresAuth: true,
    },
  },
  {
    path: "/reports/folders-sharing",
    name: "ReportFoldersSharing",
    component: () => import("@/views/logs/ReportsFoldersSharing.vue"),
    meta: {
      requiresAuth: true,
      title: "folders_sharing",
      breadcrumb: [
        { key: "home", path: "Home", show: true },
        { key: "reports", path: "Report", show: true },
        {
          key: "folders_sharing",
          path: "ReportFoldersSharing",
          show: true,
        },
      ],
    },
  },
  {
    path: "/suspended",
    name: "Suspended",
    component: () => import("@/views/help/Suspended.vue"),
    meta: {
      title: "suspended_account",
    },
  },
  {
    path: "/billing/upgrade",
    name: "BillingUpgradePlan",
    component: () => import("@/views/billing/UpgradePlan.vue"),
    meta: {
      title: "upgrade_plan",
      requiresAuth: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach(async (to, from, next) => {
  // meta.title must be a ml key, otherwise it will break the application
  const title = to.meta.title ? router.app.$ml.get(to.meta.title) : "";
  const pageTitleKey = `Conecta Control ${title ? " - " + title : ""}`;
  document.title = pageTitleKey;

  const hasToken = !!store.getters.token;
  if (to.meta.requiresAuth && !store.getters.isAuthenticated) {
    const action = Object.keys(to.query)[0];
    if (action) {
      localStorage.setItem("nextPageAction", action);
    }
    if (!hasToken) {
      localStorage.setItem("nextPage", to.name);
      next({ name: "Login" });
    } else {
      store.commit("setIsAuthenticating", true);
      store
        .dispatch("getCurrentUser")
        .then(() => {
          if (to.meta.requireAdmin) {
            if (store.getters.hasAdminPermission) {
              next();
            } else {
              next({ name: "Home" });
            }
          } else if (to.meta.requireControlAdmin) {
            if (store.getters.isControlAdmin) {
              next();
            } else {
              next({ name: "Home" });
            }
          } else {
            next();
          }
        })
        .catch(() => {
          store.dispatch("logout");
        });
    }
  } else if (["Login", "Callback"].includes(to.name) && hasToken) {
    store
      .dispatch("getCurrentUser")
      .then(() => {
        next({ name: "Home" });
      })
      .catch(() => {
        store.dispatch("logout");
      });
  } else if (to.name === "ResetPassword" && hasToken) {
    store
      .dispatch("getCurrentUser")
      .then(() => {
        next({ name: "Home" });
      })
      .catch(() => {
        store.dispatch("logout");
        next();
      });
    next({ name: "Home" });
  } else if (to.name === "ActivationSteps" && store.getters.isSsoActive) {
    next({ name: "SuccessfulActivation" });
  } else if (
    to.name === "SuccessfulActivation" &&
    !store.getters.isSsoActive &&
    !store.getters.hasScheduleActivationInfo
  ) {
    next({ name: "ActivationSteps" });
  } else {
    next();
  }
  window.scrollTo({ top: 0, behavior: "smooth" });
});

router.afterEach(async () => {
  store.commit("setIsAuthenticating", false);
});

export default router;
